<div class="dashboard-right-inner">

    <!--breadcrumb-->
    <Breadcrumb :id="$route.params.id" />

    <div class="max-height-100--60 overflow-y-auto">

        <div class="dashboard-header d-flex justify-content-between align-items-md-end align-items-sm-start align-items-start">
            <div>
                <h2 class="font-28 font-34-sm-big font-w-300">Create Digital Pass</h2>
                <p class="font-14 color-light2 m-0"> Set-up your digital pass template information for {{isClub()}} members</p>
            </div>

            <kbutton :fill-mode="'flat'" @click="onCancel">Cancel</kbutton>
        </div>


        <!--dashboard-content-->
        <div class="dashboard-content p-0">
            <div class="row m-0">

                <div class="col-md-6 p-0">
                    <div class="create-digital-pass-left d-flex justify-content-center align-items-center">
                        <div class="width-437">
                            <div class="k-form-field">
                                <label>Which type of digital pass do you want to create?</label>
                                <div class="mb-24">
                                    <dropdownlist :data-items="templates" :text-field="'name'" :data-item-key="'id'"
                                        v-model="selectedTemplate">
                                    </dropdownlist>
                                </div>
                            </div>

                            <div class="k-form-field">
                                <label>Template Name *</label>
                                <k-input :placeholder="'New Template'" v-model="title" @keyup="errorMsg=''"></k-input>
                                <span class="error-msg">{{errorMsg}}</span>
                            </div>

                            <kbutton :disabled="!title" :theme-color="'primary'" @click="saveData" :class="'flex-none px-5 w-100-sm'">Continue to Create
                            </kbutton>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 p-0">
                    <div class="create-digital-pass-right">
                        <div v-if="this.selectedTemplate.id === 3" class="row justify-content-center">
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Google Pass</h6>
                                <img src="../../../../assets/images/google-pass.png">
                                <section>

                                </section>
                            </div>
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Apple Pass</h6>
                                <img src="../../../../assets/images/apple-pass.png">
                            </div>
                        </div>
                        <div v-else-if="this.selectedTemplate.id === 2" class="row">
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Google Pass</h6>
                                <img src="../../../../assets/images/membership-google-pass.png">
                                <section>

                                </section>
                            </div>
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Apple Pass</h6>
                                <img src="../../../../assets/images/membership-apple-pass.png">
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Google Pass</h6>
                                <img src="../../../../assets/images/loyality-google-pass.png">
                                <section>

                                </section>
                            </div>
                            <div class="col-md-6 col-sm-6 mb-sm-0 col-12 mb-4 text-center">
                                <h6 class="font-w-700 text-color mb-3">Apple Pass</h6>
                                <img src="../../../../assets/images/loyality-apple-pass.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>