<template src="./DigitalPassCreate.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { HTTP } from "@/service";
import { configuration } from "@/configurationProvider";

export default {
  components: {
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    Breadcrumb,
    dropdownlist: DropDownList,
  },

  data: function () {
    return {
      templates: [],
      // selectedTemplate: { text: "Event Tickets", id: 3 },
      selectedTemplate: {},
      title: "",
      template_id: "0",
      errorMsg: "",
    };
  },
  mounted() {
    const payload = {
      clubId: this.$route.params.id,
    };
    HTTP.get(
      `${configuration.apiBaseUrl}/api/v1/Pass/types` +
        `?clubId=${payload.clubId}`
    )
      .then((response) => {
        this.templates = response.data.data;
        this.selectedTemplate = response.data.data[0];
      })
      .catch((error) => {});
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
  },
  methods: {
    saveData() {
      if (this.title.trim().length == 0) {
        this.errorMsg = "A template name is required.";
        return;
      }
      if (this.title == "") {
        this.errorMsg = "Template name is required.";
        return;
      }
      if (this.title.length > 50) {
        this.errorMsg = "Template name must not exceed 50 characters.";
        return;
      }

      this.$router.push({
        name: "EditPass",
        query: {
          template_id: this.template_id,
          templateType: this.selectedTemplate.id,
          title: this.title,
        },
      });
    },
    isValidTitle() {
      return this.title && this.title.trim.length != 0;
    },
    onCancel() {
      this.$router.push(
        "/club-details/" + this.$route.params.id + "/digitalpass"
      );
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
